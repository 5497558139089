<template>
  <div>
    <v-container pt-2 pb-1>
      <page-title title="Menu 總表" />
    </v-container>

    <v-container>
      <v-list dense v-if="true" nav>
        <div v-for="(item, index) in menuData.provider" :key="index">
          <block-title class="d-flex justify-space-between pl-5" :class="item.group ? 'block' : ''" @click.native="item.group ? toggleCollapse(index) : linkto(item.route)">
            <div v-if="!item.group">
              <span>{{ item.label }}</span>
            </div>
            <div v-else class="block-title">
              <span>{{ item.label }}</span>
            </div>
            <div v-if="item.group">
              <v-icon v-if="isCollapsed(index)" color="primary">fa fa-chevron-down</v-icon>
              <v-icon v-else color="primary">fa fa-chevron-up</v-icon>
            </div>
          </block-title>
          <div v-show="!isCollapsed(index)">
            <v-row class="pb-5 pl-10">
              <v-col cols="12" v-for="i in item.group" :key="i.route.name" @click="linkto(i.route)">
                <span class="font-weight-bold">{{ i.label }}</span>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-list>
    </v-container>
  </div>
</template>

<script>
import getMenu from "@/modules/base/config/menu";

export default {
  data: () => ({
    listKey: "liff-provider-menu",
    menuData: {},
    collapseStatus: {}
  }),
  created() {
    this.menuData = getMenu();
    this.initializeCollapseStatus();
  },
  methods: {
    initializeCollapseStatus() {
      this.menuData.provider.forEach((_, index) => {
        this.$set(this.collapseStatus, index, true);
      });
    },
    toggleCollapse(index) {
      this.$set(this.collapseStatus, index, !this.collapseStatus[index]);
    },
    isCollapsed(index) {
      return this.collapseStatus[index];
    },
    linkto(route) {
      this.$router.push({ name: route.name, params: route.params || {} })
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  color: #000;
}

.block {
  .block-title {
    color: #004737;
  }
  background: #DFEDE9 !important;
  padding: 10px 15px 10px 0;
  border-radius: 5px;
}
</style>
